.home {
  color: #778686;
}

h2 {
  font-size: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

.stick-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  /* background-color: #051319; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  /* border: white 1px solid; */
}

.nav-logo {
  width: 20%;
  height: 50px;
}

.nav-group {
  padding: 0 50px;
}

.nav-item {
  margin: 0 10px;
  padding: 0 10px;
  color: #778686;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.buffer-container,
.parallax-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parallax-container {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.buffer-container {
  background-color: #060e11;
  /* background-color: #051319; */
  padding: 20px 0;
}


.container1 {
  background-image: url("../../public/images/cover.jpg");

}

/* .container2 {} */

.container3 {
  background-image: url("../../public/images/car-web.webp");
}

.container4 {
  height: 100vh;
}

.container5 {
  background-image: url("../../public/images/dark-jeanmichel.jpg");
  background-position: 35%;
}

.container7 {
  background-image: url("../../public/images/dark-chris.jpg");
  background-position: left;
}

.bio,
.uncrushed-description {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  max-width: 700px;
  margin: 0 auto;
  padding: 0px 20px;
}

.bio {
  font-size: 24px;
}

.uncrushed-description {
  text-align: center;
}

.uncrushed-description {
  padding: 0px 20px;
}

iframe.youtube-embed {
  /* width: 80vw;
  height: 50vh;
  border: none; */
  width: 94%;
  /* padding: 20px; */
  margin: 0 5px;
  aspect-ratio: 16 / 9;
}

.embed-container {
  /* position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; */
  max-width: 100%;
  min-width: 70%;
  padding: 0 5px;
  margin: 20px 0;
}

/* when viewport is small, embed-container width is 100% */
@media screen and (max-width: 600px) {
  .embed-container {
    width: 100%;
  }
}

/* when viewport is wider than 600px, embed-container min width is 600px */
@media screen and (min-width: 600px) {
  .embed-container {
    width: 70%;
    min-width: 600px;
  }
}


.embed-container iframe,
.embed-container object,
.embed-container embed {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
}

.email-address {
  font-weight: bold;
  color: white;
}