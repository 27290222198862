.epk {
    color: #dadada;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;

    background-image: url("../../public/images/lines2.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.main {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    max-width: 900px;
    padding: 0 50px;

}

h1 {
    font-size: 50px;

}

.parallax {
    /* The image used */
    background-image: url("../../public/images/cover.jpg");

    /* Set a specific height */
    /* min-height: 500px; */
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

a {
    color: #dadada;

}